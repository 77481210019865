import './App.css';
import IndexPage from './pages/IndexPage';


function App() {
  return (
    <IndexPage />
  );
}

export default App;
