import React from 'react'

const Credit = () => {
    return (
        <div className='credit-section'>
            <div className="credit">
                created by priyanshum003 - devChallenges.io
            </div>
        </div>
    )
}

export default Credit